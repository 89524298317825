angular.module('cpformplastApp')
  .controller('MachineScheduleModal', function ($scope, Notification, Util, DataManager, $modalInstance, machines) {

    $scope.machines = machines;
    $scope.minDate = new Date();
    $scope.startingHours = [5,6,7,8,9,10,11];
    $scope.endingHours = [13,14,15,16,17,18,19,20,21,22,23];

    $scope.startDate = undefined;
    $scope.endDate = undefined;
    $scope.startingHour = undefined;
    $scope.endingHour = undefined;
    $scope.weekSchedule = true;
    $scope.weekendSchedule = false;

    $scope.setStartingHour = function(hour) {
      $scope.startingHour = hour;
    }

    $scope.setEndingHour = function(hour) {
      $scope.endingHour = hour;
    }

    $scope.save = function() {
      if (!$scope.startingHour || !$scope.endingHour || !$scope.startDate || !$scope.endDate) {
        Notification.error("Formulaire incomplet");
        return false;
      }

      $scope.endDate.setDate($scope.endDate.getDate() + 1);

      DataManager.fetchMachines($scope.startDate, $scope.endDate)
      .then(machines => {
        for (var i=0 ; i<machines.length ; i++) {
          machines[i].isSelected = $scope.machines[i].isSelected;
        }

        $scope.detectSaveCompletion(machines);

        for (var i=0 ; i<machines.length ; i++) {

          if (machines[i].isSelected) {

            for (var j=0 ; j<machines[i].schedule.length ; j++) {
              let schedule = machines[i].schedule[j];

              if ( ($scope.weekSchedule && Util.isWeekDay(schedule.date)) || ($scope.weekendSchedule && !Util.isWeekDay(schedule.date)) ) {
                schedule.isOn = true;

                if (!schedule.start) { schedule.start = new Date(schedule.date); }
                schedule.start.setHours($scope.startingHour, 0, 0, 0);
                if (!schedule.end) { schedule.end = new Date(schedule.date); }
                schedule.end.setHours($scope.endingHour, 0, 0, 0);

                schedule.save()
                .then(data => {
                  data.isSaved = true;
                  $scope.detectSaveCompletion(machines);
                })
                .catch(err => {
                  console.error(err);
                  Notification.error("Un problème est survenu");
                });
              }
            }

          }
        }
      })
      .catch(err => {
        console.error(err);
        Notification.error("Un problème est survenu");
      });
    }

    $scope.detectSaveCompletion = function(machines) {
      var completed = true;

      for (var i=0 ; i<machines.length ; i++) {
        machines[i].isSelected = $scope.machines[i].isSelected;

        if (machines[i].isSelected) {

          for (var j=0 ; j<machines[i].schedule.length ; j++) {
            let schedule = machines[i].schedule[j];
            if ( ($scope.weekSchedule && Util.isWeekDay(schedule.date)) || ($scope.weekendSchedule && !Util.isWeekDay(schedule.date)) ) {
              if (!machines[i].schedule[j]) { completed = false}
            }
          }

        }
      }

      if (completed) { $scope.$dismiss(); }
    }

  });
